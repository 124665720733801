import { PageRenderer } from 'components/page-renderer';
import { PageView } from 'modules/pages/use-cases/GetPage/types';
import { ReactElement } from 'react';
import { getPage } from 'services/pages';
import { useRouter } from 'next/router';
import DefaultLayout from 'components/layout/Default';
import Head from 'next/head';
import Loader from 'components/layout/Loader';
import useService from 'hooks/useService';

const PageSlugView = () => {
  const router = useRouter();
  const { slug } = router.query;

  const { data, isLoading } = useService<PageView>(
    router.isReady ? `/api/pages/${slug}/` : null,
    getPage
  );

  if (isLoading) return <Loader />;
  if (!data) {
    if (router.isReady && !isLoading) router.push('/404');
    return <Loader />;
  }
  const content = JSON.parse(data.content);
  return (
    <>
      <Head>
        <title>{data.title}</title>
      </Head>
      <div className="relative min-h-full">
        <PageRenderer
          sections={content.sections.filter(
            (s: any) => s.content.status === 'published'
          )}
          page={data}
        />
      </div>
    </>
  );
};

PageSlugView.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};
export default PageSlugView;
